<template>
    <div
        :class="[
            {
                'form-field': props.fieldClass !== false,
                'form-field--no-width': props.noWidth,
                'form-field--shrink': props.shrink,
            },
            props.fieldClass
        ]"
    >
        <slot />
    </div>
</template>

<script setup lang="ts">
    export interface Props {
        fieldClass?: string | string[] | Record<string, boolean> | boolean;
        noWidth?: boolean;
        shrink?: boolean;
    }

    const props = withDefaults(defineProps<Props>(), {
        fieldClass: undefined,
        noWidth: false,
        shrink: false,
    });
</script>

<style lang="postcss" scoped>
    .form-field {
        @apply flex-1 mb-4 px-2;

        @screen sm {
            @apply min-w-48;

            &--no-width {
                @apply min-w-0;
            }
        }

        &--shrink {
            @apply flex-none min-w-0;
        }
    }
</style>
